<script>
import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import { mapActions, mapState } from 'vuex';
import { navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FilterTableComponent, PageView } from '@/components';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import pricing from '../../router/PricingRouterMap';
import { tariffListFilters, tariffListSchema } from './tariffList.config';
import DeleteTariffModal from './components/DeleteTariffModal';

export default {
  name: 'TariffsListView',
  components: {
    FilterTableComponent,
    DeleteTariffModal,
    PageView,
  },
  data() {
    return {
      isDeleteModalOpen: false,
      tariffToDelete: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
      operatorUuid: state => state.operators.active.uuid,
    }),
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      tariffs: state => map(orderBy(get(state, 'tariffsList.data.data', []), ['updatedAtTs'], ['desc']), tariff => ({
        ...tariff,
        hasActiveModifiers: size(tariff.activeModifiers) ? 1 : 0,
      })),
      tariffsStatus: state => state.tariffsList.STATUS,
      deleteTariffError: state => get(state, 'deleteTariff.error'),
    }),

    tariffListSchemaComputed() {
      return tariffListSchema(this.operatorTimezone);
    },
  },
  watch: {
    async operatorUuid() {
      await this.getTariffs(this.operatorUuid);
    },
  },
  async created() {
    this.tariffListFilters = tariffListFilters;
    this.tariffListRowActions = [
      {
        hideAction: ({ isMigrated }) => !isMigrated,
        label: 'Edit tariff',
        labelClass: 'emobg-font-weight-semibold',
        action: tariff => {
          if (get(tariff, 'isMigrated')) {
            this.$router.push({ name: pricing.tariffs.update, params: { tariffUuid: tariff.uuid } })
              .catch(navigationErrorHandler);
          }
        },
      },
      {
        label: 'Delete tariff',
        labelClass: 'emobg-color-danger emobg-font-weight-semibold',
        action: tariff => {
          this.tariffToDelete = tariff;
          this.isDeleteModalOpen = true;
        },
      },
    ];
    await this.getTariffs(this.operatorUuid);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.pricing.tariffs, [
      'getTariffs',
    ]),
    newTariff() {
      this.$router.push({ name: pricing.tariffs.create }).catch(navigationErrorHandler);
    },
    closeModal() {
      this.isDeleteModalOpen = false;
      this.tariffToDelete = null;
    },
    onDeleteError() {
      const message = get(this, 'deleteTariffError.message') || get(this, 'deleteTariffError.exceptionMessage') || 'Tariff cannot be deleted';
      this.$notify({
        message,
        textAction: '',
        type: NOTIFICATION_TYPES.error,
      });
    },
    async onFormSuccess() {
      await this.getTariffs(this.operatorUuid);
    },
  },
};
</script>

<template>
  <PageView class="TariffsListView">
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Tariffs
      </h1>
      <div class="d-flex">
        <ui-button
          data-test-id="create_tariff-button"
          @clickbutton="newTariff"
        >
          Create new tariff
        </ui-button>
      </div>
    </div>
    <ui-loader
      v-if="tariffsStatus.LOADING"
      label="Loading tariff list..."
      data-test-id="loader"
    />
    <FilterTableComponent
      v-else
      :schema="tariffListSchemaComputed"
      :data="tariffs"
      :filters="tariffListFilters"
      :per-page="50"
      :row-actions="tariffListRowActions"
      data-test-id="list"
      class="emobg-border-1 emobg-border-color-ground"
      export-name="tariffs"
      searchable
      exportable
      filters-visible
    />
    <DeleteTariffModal
      v-if="isDeleteModalOpen"
      :tariff="tariffToDelete"
      :on-delete-success="onFormSuccess"
      :on-delete-error="onDeleteError"
      data-test-id="delete_tariff"
      @closeModal="closeModal"
    />
  </PageView>
</template>
